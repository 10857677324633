import isFunction from 'lodash/isFunction';
import Cookies from 'js-cookie';
import { RSAA } from 'redux-api-middleware';
import {
  pendingTask, // The action key for modifying loading state
  begin, // The action value if a "long" running task begun
  end, // The action value if a "long" running task ended
} from 'react-redux-spinner';

export const API_URL = '';

export function createApiAction(namespace, data) {
  const requestActionType = {
    type: `${namespace}_REQUEST`,
    meta: { [pendingTask]: begin, error: false },
  };
  const successActionType = {
    type: `${namespace}_SUCCESS`,
    meta: { [pendingTask]: end, error: false },
  };
  const failureActionType = {
    type: `${namespace}_FAILURE`,
    meta: { [pendingTask]: end, error: true },
  };

  const actionCreator = (...args) => {
    let payload;

    if (isFunction(data)) {
      payload = data(...args);
    } else {
      payload = data;
    }

    const { headers, ...rest } = payload;
    const apiHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
      ...headers,
    };

    if (!apiHeaders['Content-Type']) {
      delete apiHeaders['Content-Type'];
    }

    return {
      [RSAA]: {
        method: 'GET',
        headers: apiHeaders,
        credentials: 'same-origin',
        types: [requestActionType, successActionType, failureActionType],
        ...rest,
      }
    };
  };
  actionCreator.REQUEST = requestActionType.type;
  actionCreator.SUCCESS = successActionType.type;
  actionCreator.FAILURE = failureActionType.type;
  return actionCreator;
}

export function formatFormErrors(errors) {
  const keys = Object.keys(errors);
  const formattedErrors = [];
  for (let i = 0; i < keys.length; i++) {
    formattedErrors.push({
      type: 'required',
      name: keys[i],
      message: errors[keys[i]][0], // [0] is not correct as ther could be multiple errors per field
    });
  }
  return formattedErrors;
}

export function formApiAction(actionCreator) {
  return (...args) => (dispatch) => {
    const setError = args[1];
    const x = () => new Promise((resolve, reject) => {
      dispatch(actionCreator(...args)).then((response) => {
        if (response.error) {
          const formattedErrors = formatFormErrors(response.payload.response);
          setError(formattedErrors);
          reject(response);
        } else {
          resolve(response);
        }
      });
    });
    return dispatch(x);
  };
}
