// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".error__errorContainer___2PtxZ{display:flex;align-items:center;justify-content:center;min-height:100vh;font-family:\"Futura PT W01 Demi\";text-transform:uppercase}.error__errorContainer___2PtxZ h1{font-size:2rem}\n", "",{"version":3,"sources":["error.scss"],"names":[],"mappings":"AAAA,+BAAgB,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,gBAAgB,CAAC,gCAAgC,CAAC,wBAAwB,CAAC,kCAAmB,cAAc","file":"error.scss","sourcesContent":[".errorContainer{display:flex;align-items:center;justify-content:center;min-height:100vh;font-family:\"Futura PT W01 Demi\";text-transform:uppercase}.errorContainer h1{font-size:2rem}\n"]}]);
// Exports
exports.locals = {
	"errorContainer": "error__errorContainer___2PtxZ"
};
module.exports = exports;
