import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { entriesSelector } from '../../selectors/entries';

import styles from './day.scss';

const Day = React.memo((props) => {
  const location = useLocation();
  const {
    columnIndex, rowIndex, data, style,
  } = props;

  const singleColumnIndex = columnIndex + rowIndex * 7;
  const item = data[singleColumnIndex];
  const entries = entriesSelector(item.entries);
  let thumbnailUrl = '';
  let url = '';

  if (entries.length > 0 && entries[0].images.length > 0) {
    thumbnailUrl = entries[0].images[0].thumbnail;
    url = `/day/${item.date}`;
  }

  return (
    <Link
      to={{
        pathname: url,
        state: { background: location }
      }}
      style={{
        ...style,
        left: style.left,
        top: style.top,
        width: style.width,
        height: style.height
      }}
      className={classNames(styles.gridItemWrapper, {
        [styles.placeholder]: item.type === 'placeholder',
        [styles.hasImage]: thumbnailUrl,
      })}
    >
      <div
        key={item.date}
        className={styles.gridItem}
        style={{
          backgroundImage: `url(${thumbnailUrl})`
        }}
      >
        <div className={styles.gridItemHeader}>
          <div className={styles.date}>
            {dayjs(item.date, 'YYYYMMDD').format('D.M.')}
          </div>
        </div>
        <div className={styles.gridItemContent}>
          <div className={styles.entriesList}>

          </div>
        </div>
      </div>
    </Link>
  );
});

Day.propTypes = {
  style: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  columnIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
};

export default Day;
