import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { spring, AnimatedSwitch } from 'react-router-transition';

import CalendarView from '../views/calendar';
import DayDetailView from '../views/day-detail';

import NoMatch from '../views/error/error';

import styles from '../../scss/app.scss';

const mapStyles = (styles) => ({
  opacity: styles.opacity,
  transform: `scale(${styles.scale})`,
});

const bounce = (val) => spring(val, {
  stiffness: 330,
  damping: 22,
});

const bounceTransition = {
  atEnter: {
    opacity: 0,
    // scale: 1.2,
  },
  atLeave: {
    opacity: 0 //bounce(0),
    // scale: bounce(0.8),
  },
  atActive: {
    opacity: 1// bounce(1),
    // scale: bounce(1),
  },
};

const Routes = () => {
  const location = useLocation();
  const background = location.state && location.state.background;
  return (
    <React.Fragment>
      <Switch
        location={background || location}
        className={styles.switch}
      >
        <Route exact path="/" component={CalendarView} />
        <Route exact path="/day/:id" component={DayDetailView} />
      </Switch>

      <AnimatedSwitch
        atEnter={bounceTransition.atEnter}
        atLeave={bounceTransition.atLeave}
        atActive={bounceTransition.atActive}
        className={styles.animatedSwitch}
        mapStyles={mapStyles}
      >
        {background && <Route exact path="/day/:id" component={DayDetailView} />}
      </AnimatedSwitch>

      <Switch>
        <Route exact path="/day/:id" component={DayDetailView} />
        <Route component={NoMatch} />
      </Switch>
    </React.Fragment>
  );
};

Routes.propTypes = {
};

export default Routes;
