import { createAction } from 'redux-actions';
import { createApiAction, API_URL } from './api';

export const getDays = createApiAction('GET_DAYS', () => ({
  endpoint: `${API_URL}/api/entries/grouped/`,
  method: 'GET',
}));

export const CREATE_DUMMY_DAYS = 'CREATE_DUMMY_DAYS';

export const createDummyDays = createAction(CREATE_DUMMY_DAYS);
