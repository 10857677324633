import produce from 'immer';

import {
  loginUser,
  logoutUser,
  getUser,
  changePassword,
  RESET_PASSWORD_CHANGED,
} from '../actions/auth';

const initialState = {
  loading: false,
  loggedIn: false,
  user: undefined,
  passwordUpdated: false,
};

const auth = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case loginUser.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case loginUser.SUCCESS: {
      return {
        ...state,
        loading: false,
        key: action.payload.key,
        loggedIn: true,
      };
    }
    case loginUser.FAILURE: {
      return {
        ...state,
        loading: false,
        loggedIn: false,
      };
    }
    case logoutUser.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case logoutUser.SUCCESS: {
      return {
        ...state,
        loading: false,
        key: '',
        loggedIn: false,
      };
    }
    case logoutUser.FAILURE: {
      return {
        ...state,
        loading: false,
        key: '',
        loggedIn: false,
      };
    }
    case getUser.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case getUser.SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload,
        loggedIn: true,
      };
    }
    case getUser.FAILURE: {
      return {
        ...state,
        loading: false,
        loggedIn: false,
      };
    }
    case changePassword.REQUEST: {
      return {
        ...state,
        loading: true,
        passwordUpdated: false,
      };
    }
    case changePassword.SUCCESS: {
      return {
        ...state,
        loading: false,
        passwordUpdated: true,
      };
    }
    case changePassword.FAILURE: {
      return {
        ...state,
        loading: false,
        loggedIn: false,
      };
    }
    case RESET_PASSWORD_CHANGED: {
      return {
        ...state,
        loading: false,
        passwordUpdated: false,
      };
    }
    default:
      return state;
  }
});

export default auth;
