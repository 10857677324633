import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, Link, useLocation} from 'react-router-dom';
import { useKeyPress, useKey } from 'react-use';
import dayjs from 'dayjs';

import { entriesSelector } from '../../selectors/entries';
import { daysSelector } from '../../selectors/days';

import IconClose from '../../../assets/icons/close.svg';
import IconArrowLeft from '../../../assets/icons/arrow-left.svg';
import IconArrowRight from '../../../assets/icons/arrow-right.svg';

import styles from './day-detail.scss';

const DayDetailView = (props) => {
  const location = useLocation();
  const history = useHistory();
  let { id } = useParams();
  let imagelUrl = '';
  let title = '';
  let date = '';
  let nextDayLink = '';
  let previousDayLink = '';

  // useKey('ArrowUp', console.log('asdfasf'));

  const days = useSelector((state) => state.days.days);
  const daysWithEntries = daysSelector(useSelector((state) => state.days.daysWithEntries));
  const escapeIsPressed = useKeyPress('Escape');

  // const arrowRightIsPressed = useKeyPress('ArrowRight');
  // const arrowLeftIsPressed = useKeyPress('ArrowLeft');
  const index = daysWithEntries.map(function (entry) { return entry.date; }).indexOf(id);

  if (daysWithEntries.length > 0) {
    if (daysWithEntries.length > index + 1) {
      nextDayLink = `/day/${daysWithEntries[index + 1].date}`;
    }

    if (daysWithEntries.length === index + 1) {
      nextDayLink = `/day/${daysWithEntries[0].date}`;
    }

    if (index > 0) {
      previousDayLink = `/day/${daysWithEntries[index - 1].date}`;
    }
  }

  if (days[id]) {
    date = dayjs(days[id].date, 'YYYYMMDD').format('D.M.YYYY');
    const entries = entriesSelector(days[id].entries);
    if (entries.length > 0 && entries[0].images.length > 0) {
      imagelUrl = entries[0].images[0].image;
    }
    if (entries.length > 0) {
      title = entries[0].title;
    }
  }

  useEffect(() => {
    if (escapeIsPressed[0] === true) {
      history.push('/');
    }
  }, [escapeIsPressed]);

  // useEffect(() => {
  //   if (arrowRightIsPressed[0] === true) {
  //     console.log('pressed')
  //     history.push(`/day/${tomorrow.format('YYYYMMDD')}`, { background: location });
  //   }
  // }, [arrowRightIsPressed]);

  const handleClose = () => {
    history.push('/');
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {/* <div className={styles.date}>
          {date}
        </div> */}
        <div className={styles.title}>
          {title}
        </div>
        <div
          onClick={handleClose}
          className={styles.closeButton}
        >
          <IconClose />
        </div>
      </div>
      <div className={styles.subHeader}>
        {date}
      </div>
      <div
        className={styles.content}
        style={{
          backgroundImage: `url(${imagelUrl})`
        }}
      >
        <Link
          to={{
            pathname: nextDayLink,
            state: { background: location }
          }}
          className={styles.nextButton}
        >
          <IconArrowRight />
        </Link>
        <Link
          to={{
            pathname: previousDayLink,
            state: { background: location }
          }}
          className={styles.previousButton}
        >
          <IconArrowLeft />
        </Link>
      </div>
    </div>
  );
};

DayDetailView.propTypes = {
};

export default DayDetailView;
