import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowSize } from 'react-use';

import { daysSelector } from '../../selectors/days';
import { getCalendarDetail } from '../../actions/calendars';
import { createDummyDays, getDays } from '../../actions/days';

import styles from './calendar.scss';

import Calendar from '../../components/calendar';

const CalendarView = (props) => {
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();
  const calendarDetail = useSelector((state) => state.calendars.calendarDetail);
  const days = daysSelector(useSelector((state) => state.days.days));
  const startDayIndex = useSelector((state) => state.days.startDayIndex);
  useEffect(() => {
    dispatch(getCalendarDetail());
  }, []);

  useEffect(() => {
    if (calendarDetail.start_date) {
      dispatch(createDummyDays(calendarDetail.start_date));
      dispatch(getDays());
    }
  }, [calendarDetail]);

  if (!calendarDetail.start_date) {
    return (
      <div></div>
    );
  }

  return (
    <div className={styles.container}>
      <Calendar
        days={days}
        width={width}
        height={height}
        startDayIndex={startDayIndex}
      />
    </div>
  );
};

CalendarView.propTypes = {
};

export default CalendarView;
