import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';

import configureStore from './configure-store';


// const incrementMiddleware = store => next => action => {
//   console.log(action);
//   // if(action.type === 'INCREMENT') {
//   //   alert(`Increment button was clicked, current state is ${store.getState()} \nI will now add to it`);
//   // }
//   next(action);
// }

const logger = createLogger();

const middleware = [
  thunk,
  apiMiddleware,
  logger,
  // incrementMiddleware,
];

const store = configureStore({}, middleware);

export default store;
