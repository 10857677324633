import produce from 'immer';

import {
  getCalendarDetail
} from '../actions/calendars';

const initialState = {
  calendarDetail: {
    start_date: undefined,
  },
  loading: false
};

const calendars = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case getCalendarDetail.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case getCalendarDetail.SUCCESS: {
      const stateDraft = draft;
      stateDraft.calendarDetail = action.payload;
      stateDraft.loading = false;
      break;
    }
    case getCalendarDetail.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
});

export default calendars;
