import produce from 'immer';
import dayjs from 'dayjs';

import {
  getDays, CREATE_DUMMY_DAYS
} from '../actions/days';

const initialState = {
  days: {},
  daysWithEntries: {},
  dayDetail: {},
  loading: false,
  startDayIndex: 1,
};

const days = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    // ---------------------------------------------- GET DAYS
    case getDays.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case getDays.SUCCESS: {
      const stateDraft = draft;
      const days = action.payload.results;
      days.forEach((day) => {
        const entries = {};
        day.entries.forEach((memory) => {
          entries[memory.id] = memory;
        });
        stateDraft.days[day.day] = {
          date: day.day,
          type: 'date',
          entries,
        };
        stateDraft.daysWithEntries[day.day] = {
          date: day.day,
          type: 'date',
          entries,
        };
      });
      stateDraft.loading = false;
      break;
    }
    case getDays.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- CREATE DUMMY DAYS
    case CREATE_DUMMY_DAYS: {
      const stateDraft = draft;
      const startDate = dayjs(action.payload).toDate();
      const endDate = new Date('2021-01-03T03:24:00');
      const dayOfWeek = dayjs(startDate, 'YYYYMMDD').day();
      const startDateNumber = dayOfWeek - 1;
      stateDraft.startDayIndex = startDateNumber;
      if (dayOfWeek === 0) {
        for (let i = -6; i < 0; i += 1) {
          const newDate = dayjs(startDate, 'YYYYMMDD').day(i).format('YYYYMMDD');
          stateDraft.days[newDate] = {
            date: newDate,
            type: 'placeholder',
            entries: {},
          };
        }
      } else {
        for (let i = 0; i < startDateNumber; i += 1) {
          const newDate = dayjs(startDate, 'YYYYMMDD').day(i).format('YYYYMMDD');
          stateDraft.days[newDate] = {
            date: newDate,
            type: 'placeholder',
            entries: {},
          };
        }
      }

      while (startDate <= endDate) {
        const day = dayjs(new Date(startDate)).format('YYYYMMDD');
        stateDraft.days[day] = {
          date: day,
          type: 'date',
          entries: {},
        };
        startDate.setDate(startDate.getDate() + 1);
      }
      stateDraft.loading = false;
      break;
    }
    default:
      return state;
  }
});

export default days;
