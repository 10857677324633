import produce from 'immer';
import { SET_USER_LOCATION } from '../actions/ui';

const initialState = {
  geolocation: { lat: 50.953609, lng: 10.199785 },
};

const ui = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case SET_USER_LOCATION: {
      const stateDraft = draft;
      stateDraft.geolocation = action.payload;
      break;
    }
    default:
      return state;
  }
});

export default ui;
