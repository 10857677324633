import { createAction } from 'redux-actions';

import { createApiAction, API_URL } from './api';

export const loginUser = createApiAction('LOGIN_USER', (formData) => ({
  endpoint: `${API_URL}/api/login/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const logoutUser = createApiAction('LOGOUT_USER', {
  endpoint: `${API_URL}/api/logout/`,
  method: 'POST',
});

export const registerUser = createApiAction('REGISTER_USER', (formData) => ({
  endpoint: `${API_URL}/api/register/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const getUser = createApiAction('GET_USER', () => ({
  endpoint: `${API_URL}/api/me/`,
  method: 'GET',
}));

export const changePassword = createApiAction('PASSWORD_CHANGE', (formData) => ({
  endpoint: `${API_URL}/api/password/change/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const RESET_PASSWORD_CHANGED = 'RESET_PASSWORD_CHANGED';

export const resetPasswordChanged = () => ({
  type: RESET_PASSWORD_CHANGED,
});
