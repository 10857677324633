import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './error.scss';



const ErrorView = () => {
  const loggedIn = useSelector(state => state.auth.loggedIn);
  console.log(loggedIn)
  const history = useHistory();


  return (
    <div className="page">
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>
          <h1>Sorry, something went wrong 💩</h1>
        </div>
      </div>
    </div>
  );
};

export default ErrorView;
