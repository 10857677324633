// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".calendar__container___25Nb1{position:absolute;top:0px;right:0px;bottom:0px;left:0px;overflow:hidden;background-color:#eee}\n", "",{"version":3,"sources":["calendar.scss"],"names":[],"mappings":"AAAA,6BAAW,iBAAiB,CAAC,OAAO,CAAC,SAAS,CAAC,UAAU,CAAC,QAAQ,CAAC,eAAe,CAAC,qBAAqB","file":"calendar.scss","sourcesContent":[".container{position:absolute;top:0px;right:0px;bottom:0px;left:0px;overflow:hidden;background-color:#eee}\n"]}]);
// Exports
exports.locals = {
	"container": "calendar__container___25Nb1"
};
module.exports = exports;
