import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import dayjs from 'dayjs';

import ItemRenderer from '../day';

import styles from './calendar.scss';

function itemKey({ columnIndex, data, rowIndex }) {
  const item = data[rowIndex];
  return `${item.date}-${columnIndex}`;
}

const Calendar = (props) => {
  const { days, startDayIndex, height, width } = props;
  if (days.length === 0) {
    return <div>No Calendar Available</div>;
  }

  const HALF_RATIO = 1.333333333333333;
  const COLUMN_WIDTH = (width) / 7;
  const ROW_HEIGHT = (width - 50) / 7 * HALF_RATIO;
  const COLUMN_COUNT = 7;
  const [firstVisibleItem, setFirstVisibleItem] = useState(days[startDayIndex]);

  const handleOnItemsRendered = (e) => {
    setFirstVisibleItem(days[startDayIndex + e.visibleRowStartIndex * COLUMN_COUNT]);
  };

  const renderWeekdays = () => {
    if (width > 450) {
      return (
        <React.Fragment>
          <div>Monday</div>
          <div>Tuesday</div>
          <div>Wednesday</div>
          <div>Thursday</div>
          <div>Friday</div>
          <div>Saturday</div>
          <div>Sunday</div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div>Mon</div>
        <div>Tue</div>
        <div>Wed</div>
        <div>Thu</div>
        <div>Fri</div>
        <div>Sat</div>
        <div>Sun</div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className={styles.calendarHeader}>
        {dayjs(firstVisibleItem.date, 'YYYYMMDD').format('MMMM YYYY')}
      </div>
      <div className={styles.calendarWeekdays}>
        {renderWeekdays()}
      </div>
      <div className={styles.container}>
        <AutoSizer>
          {({ height, width }) => (
            <Grid
              itemData={days}
              itemKey={itemKey}
              className={styles.grid}
              columnCount={COLUMN_COUNT}
              columnWidth={COLUMN_WIDTH}
              height={height}
              rowCount={days.length / 7}
              rowHeight={ROW_HEIGHT}
              width={width}
              onItemsRendered={handleOnItemsRendered}
              overscanRowCount={5}
            >
              {ItemRenderer}
            </Grid>
          )}
        </AutoSizer>
      </div>
    </React.Fragment>
  );
};

Calendar.propTypes = {
};

export default Calendar;
